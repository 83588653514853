<template>
  <div class="dashboard-wrapper">
    <admin-auth v-if="$store.state.user == null"/>
    <div v-else>
      <admin-nav />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import '../../style/auth.css'
import AdminAuth from '../../components/admin/AdminAuth.vue'
import AdminNav from '../../components/admin/AdminNav.vue'

export default {
  components: {
    AdminAuth,
    AdminNav,
  },
  methods: {
    
  },
  metaInfo() {
    return {
      title: 'Logga in'
    }
  },
  mounted() {
    this.$i18n.locale = 'sv'
  }
}
</script>

<style scoped>

</style>