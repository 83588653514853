<template>
  <div class="auth-nav full-width-padding">
    <p class="auth" style="color: white">Admin-konto: {{ $store.state.user.email }}</p>
    <div style="display: flex; flex-wrap: wrap; justify-content: flex-end; column-gap: 40px;">
      <router-link to="/"><p class="auth text-hoverable" style="color: white; text-align: right">Tillbaka till hemsidan</p></router-link>
      <p class="auth text-hoverable" style="color: white; text-align: right" @click="$store.dispatch('signOut')">Logga ut</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.auth-nav {
  height: 70px;
  background-color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
</style>