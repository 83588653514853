<template>
  <div class="auth-wrapper">
    <div class="auth-backdrop">
      <img class="auth-logo" src="@/assets/FOTH_logotyp_Roboto.png" alt="Focus on the Harvest">
      <h1 class="auth" style="padding-top: 40px">Admin</h1>
      <p class="auth">Denna inloggningsskärm är endast till för administratörer av Focus on the Harvest. Om du inte är en administratör, vänligen återgå till 
      <router-link to="/" class="auth">hemsidan</router-link>. Vid frågor eller problem att logga in, kontakta  
      <a class="auth" href="mailto:jonathan.runeke@hotmail.com">jonathan.runeke@hotmail.com</a>.</p>
      <form class="auth" style="padding-top: 20px" @submit.prevent="handleSubmit">
        <label class="auth" for="mail">Epost</label>
        <input class="auth" type="email" name="email" v-model="email" required>
        <label class="auth" for="password">Lösenord</label>
        <input class="auth" type="password" name="password" v-model="password" required>
        <p class="auth" style="margin: 0 auto; color: #D91919">{{ error }}</p>
        <button class="auth" style="position: relative">
          <div v-if="loading" class="auth-loader" style="position: absolute; left: 15px; top: 12px"></div>
          Logga in
        </button>
      </form>
    </div>
    <DevyneLogo style="margin-top: 50px"/>
  </div>
</template>

<script>
import DevyneLogo from '../../assets/devyne_logo_2.svg'
export default {
  data() {
    return {
      email: '',
      password: '',
      error: '',
      loading: false,
    }
  },
  components: {
    DevyneLogo,
  },
  methods: {
    async handleSubmit() {
      try {
        this.loading = true;
        await this.$store.dispatch('signIn', { email: this.email, password: this.password })
        this.loading = false;
      } catch(e) {
        this.loading = false;
        this.error = e.message
      }
    }
  }
}
</script>

<style scoped>

.auth-wrapper {
  max-width: 500px;
  width: 90%;
  margin: auto;
}
.auth-backdrop {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px 50px;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.075);
  margin: 20px 0;
}
.auth-logo {
  min-width: none;
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
}


</style>